<template>
  <div class="position-relative">
    <v-combobox
      ref="combobox"
      :menu-props="{ openOnClick: false, value: false }"
      :search-input.sync="searchText"
      @click="$refs.treeMenuActivator.click()"
      :multiple="multiple"
      :clearable="isClearable"
      @click:clear="clearAll"
      :error-messages="errorMessages"
      :label="label"
      :loading="loading"
      :disabled="loading || disabled"
      :value="selectedItems"
      :placeholder="placeholder"
      :return-object="returnObject"
      :hide-details="hideDetails"
      color="tertiary"
      :append-icon="appendIcon"
      dense
      outlined
      v-on:keyup.13="onEnter"
      v-on:keyup.esc="onEsc"
    >
      <template slot="selection" slot-scope="{ item, index }">
        <template v-if="multiple">
          {{ index === 0 ? `${selectedItems.length} ${$t("global.selected", [$t("global.unit")])}` : "" }}
        </template>
        <template v-else>
          {{ item[0] && item[0].name }}
        </template>
      </template>
      <template slot="item">
        <div class="text-md-center">
          no matched data
        </div>
      </template>
    </v-combobox>
    <v-menu
      class="mx-auto filterUnits"
      ref="menu"
      bottom
      :nudge-top="20"
      :close-on-content-click="false"
      max-height="300"
      v-model="menuStatus"
    >
      <template v-slot:activator="{ on }">
        <div ref="treeMenuActivator" v-on="on"></div>
      </template>
      <v-card width="100%" class="filterUnits">
        <template v-if="hierarchicalItems.length">
          <v-card-text v-if="hierarchicalItems[0].children" class="px-1 py-1">
            <div v-show="isContentExists">
              <v-treeview
                ref="treeSelect"
                return-object
                hoverable
                :items="hierarchicalItems"
                :search="searchText"
                :filter="filterItems"
                open-all
                color="primary"
              >
                <template v-slot:label="data">
                  <v-list-item v-if="multiple" class="ml-0 pl-0 unit-tile" style="padding-left: 0 !important;">
                    <v-list-item-action>
                      <v-checkbox
                        color="primary"
                        hide-details
                        v-model="data.item.isSelected"
                        @change="selectItem(data.item)"
                      ></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title style="font-size: 13px;">
                        <div>{{ data.item.name }}</div>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-else
                    class="ml-0 pl-0"
                    style="padding-left: 0 !important;"
                    @click="selectItem(data.item)"
                  >
                    <v-list-item-avatar size="30" style="font-size: 13px" :color="data.item.color">
                      <span class="white--text font-weight-bold">{{
                        data.item.name && data.item.name.substring(0, 1).toUpperCase()
                      }}</span>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name" style="font-size: 13px;"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-treeview>
            </div>
            <div v-show="!isContentExists" class="text-md-center">
              no matched data
            </div>
          </v-card-text>
          <v-card-text v-else class="pa-0">
            <v-list-item @click="selectItem(items[0])">
              <v-list-item-avatar size="30" style="font-size: 13px" :color="items[0].color">
                <span class="white--text font-weight-bold">{{ items[0].name.substring(0, 2).toUpperCase() }}</span>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="items[0].name" style="font-size: 13px;"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </template>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
  import gql from "graphql-tag";

  export default {
    name: "UnitPicker",
    props: {
      value: {
        type: [String, Array, Object],
        required: false
      },
      errorMessages: {
        default: () => [],
        type: Array
      },
      disabled: Boolean,
      multiple: Boolean,
      clearable: Boolean,
      returnObject: Boolean,
      hideDetails: Boolean,

      itemKey: {
        type: String,
        default: "id"
      },
      itemText: {
        type: [String, Array, Function],
        default: "text"
      },
      itemValue: {
        type: [String, Array, Function],
        default: "value"
      },
      label: {
        type: String,
        default: ""
      },
      appendIcon: {
        type: String,
        default: "mdi-menu-down"
      },
      placeholder: {
        type: String,
        default: ""
      },
      invalidMsg: {
        default: () => [],
        type: Array
      },
      caseSensitive: Boolean
    },
    data: vm => ({
      cachedItems: vm.cacheItems ? vm.items : [],
      content: null,
      isBooted: false,
      loading: false,
      menuStatus: false,
      items: [],
      lastItem: 20,
      initialValue: null,
      // As long as a value is defined, show it
      // Otherwise, check if multiple
      // to determine which default to provide
      lazyValue: vm.value !== undefined ? vm.value : vm.multiple ? [] : undefined,
      selectedIndex: -1,
      selectedItems: [],
      x: 0,
      y: 0,
      searchText: null,
      oldSearchText: null,
      selectedObjects: [],
      showContent: true,
      prev: null
    }),
    watch: {
      items: {
        immediate: false,
        handler(val) {
          this.filterDuplicates(val);
          this.setSelectedItems();
        }
      },
      internalValue(val) {
        // this.initialValue = val;
        this.setSelectedItems();
      },
      value(val) {
        this.lazyValue = val;
      },
      searchText: {
        handler(text) {
          if (text) {
            this.menuStatus = false;
            this.showMenu();
            this.oldSearchText = text;
          }
        }
      }
    },
    computed: {
      isClearable() {
        if (this.clearable) {
          if (this.selectedItems.length) {
            return true;
          } else if (this.searchText) {
            return false;
          }
        } else {
          return false;
        }
      },
      isContentExists() {
        return this.$refs.menu.$data.dimensions.content.height > 8;
      },
      internalValue: {
        get() {
          return this.lazyValue;
        },
        set(val) {
          this.lazyValue = val;
          // this.$emit('input', this.lazyValue)
        }
      },
      allItems() {
        return JSON.parse(JSON.stringify(this.items));
      },
      filterItems() {
        return this.caseSensitive ? (item, search, textKey) => item[textKey].indexOf(search) > -1 : undefined;
      },
      hierarchicalItems() {
        return this.buildHierarchy(this.allItems);
      },
      filteredSelectedItems: {
        get() {
          return this.selectedItems;
        },
        set(item) {
          this.selectedItems = items;
        }
      }
    },
    methods: {
      buildHierarchy(units) {
        // let internalUnits = JSON.parse(JSON.stringify(units)) // bug fix
        let hierarchy = [];
        if (units.length > 1) {
          let rootIndex = units.findIndex(unit => {
            return unit.parent === null;
          });
          let root = units[rootIndex];
          // internalUnits.splice(rootIndex, 1); // clear the null parent from list // do not remove element
          root.children = units.filter(unit => (unit.parent || {}).id === root.id);
          let currentNode = null;
          for (let i = 0; i < units.length; i++) {
            currentNode = units[i];
            currentNode.children = units.filter(unit => (unit.parent || {}).id === currentNode.id);
          }
          hierarchy.push(root);
        } else {
          hierarchy = units;
        }
        return hierarchy;
      },
      onEsc() {
        this.menuStatus = false;
      },
      onEnter() {
        // fixme  : this is workaround solution
        if (this.oldSearchText) this.searchText = this.oldSearchText;
      },
      filterDuplicates: function filterDuplicates(arr) {
        let uniqueValues = new Map();
        for (let index = 0; index < arr.length; ++index) {
          let item = arr[index];
          let val = this.getValue(item);
          // TODO: comparator
          !uniqueValues.has(val) && uniqueValues.set(val, item);
        }
        return Array.from(uniqueValues.values());
      },
      selectItem(item) {
        if (!this.multiple) {
          this.setValue(this.returnObject ? item : this.getValue(item));
          this.menuStatus = false;
        } else {
          const internalValue = (this.internalValue || []).slice();
          const i = this.findExistingIndex(item);
          if (i !== -1) {
            internalValue.splice(i, 1);
          } else {
            internalValue.push(item);
          }
          this.setValue(
            internalValue.map(i => {
              return this.returnObject ? i : this.getValue(i);
            })
          );
          // When selecting multiple
          // adjust menu after each
          // selection
          this.$nextTick(() => {
            this.$refs.menu && this.$refs.menu.updateDimensions();
            // this.$refs.combobox.focus();
          });
        }
      },
      findExistingIndex(item) {
        let itemValue = this.getValue(item);
        return (this.internalValue || []).findIndex(i => {
          return this.deepEqual(this.getValue(i), itemValue);
        });
      },
      setValue(value) {
        const oldValue = this.internalValue;
        this.internalValue = value;
        value !== oldValue && this.$emit("input", value);
      },
      setSelectedItems() {
        const selectedItems = [];
        const values = !this.multiple || !Array.isArray(this.internalValue) ? [this.internalValue] : this.internalValue;
        for (const value of values) {
          const index = this.allItems.findIndex(v => this.deepEqual(this.getValue(v), this.getValue(value)));
          if (index > -1) {
            let item = this.allItems[index];
            item.isSelected = true; // sync for selected items
            selectedItems.push(item);
          }
        }
        this.selectedItems = selectedItems;
      },
      clearAll() {
        if (this.multiple) {
          this.selectedItems.map(item => {
            item.isSelected = false;
          });
          this.selectedItems = [];
          this.internalValue = [];
        } else {
          this.internalValue = null;
        }

        this.$emit("input", this.internalValue);
      },
      getText(item) {
        return this.getPropertyFromItem(item, this.itemText, item);
      },
      getValue(item) {
        return this.getPropertyFromItem(item, this.itemValue, this.getText(item));
      },
      showMenu($event = null) {
        this.menuStatus = false;
        if ($event) {
          $event.preventDefault();
          this.X = $event.clientX;
          this.y = $event.clientY;
        }

        this.$nextTick(() => {
          this.menuStatus = true;
          if (this.$refs.treeSelect) this.$refs.treeSelect.updateAll(true);
        });
      },
      getNestedValue(obj, path, fallback) {
        let last = path.length - 1;
        if (last < 0) return obj === undefined ? fallback : obj;
        for (let i = 0; i < last; i++) {
          if (obj == null) {
            return fallback;
          }
          obj = obj[path[i]];
        }
        if (obj == null) return fallback;
        return obj[path[last]] === undefined ? fallback : obj[path[last]];
      },
      getObjectValueByPath(obj, path, fallback) {
        // credit: http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key#comment55278413_6491621
        if (!path || path.constructor !== String) return fallback;
        path = path.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
        path = path.replace(/^\./, ""); // strip a leading dot
        return this.getNestedValue(obj, path.split("."), fallback);
      },
      getPropertyFromItem(item, property, fallback) {
        if (property == null) return item === undefined ? fallback : item;
        if (item !== Object(item)) return fallback === undefined ? item : fallback;
        if (typeof property === "string") return this.getObjectValueByPath(item, property, fallback);
        if (Array.isArray(property)) return this.getNestedValue(item, property, fallback);
        if (typeof property !== "function") return fallback;
        let value = property(item, fallback);
        return typeof value === "undefined" ? fallback : value;
      },
      deepEqual(a, b) {
        if (a === b) return true;
        if (a instanceof Date && b instanceof Date) {
          // If the values are Date, they were convert to timestamp with getTime and compare it
          if (a.getTime() !== b.getTime()) return false;
        }
        if (a !== Object(a) || b !== Object(b)) {
          // If the values aren't objects, they were already checked for equality
          return false;
        }
        var props = Object.keys(a);
        if (props.length !== Object.keys(b).length) {
          // Different number of props, don't bother to check
          return false;
        }
        return props.every(p => this.deepEqual(a[p], b[p]));
      },
      async fetchItems() {
        this.loading = true;
        await this.$apollo
          .query({
            query: gql`
              {
                units {
                  id
                  name
                  color
                  employeeCount
                  parent {
                    id
                  }
                }
              }
            `,
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data: { units } }, errors) => {
            this.loading = false;
            if (!errors) this.items = units;
          })
          .catch(e => {
            console.log("TreeSelect Err: ", e.toString());
            this.loading = false;
          });
      }
    },
    created() {
      this.fetchItems();
    }
  };
</script>

<style scoped>
  .position-relative {
    position: relative;
  }
</style>
